<template>
  <v-btn
    id="totop"
    aria-label="Back to top"
    rounded="pill"
    height="64"
    width="64"
    class="bg-primary"
    :class="{ 'opacity-0': isHidden }"
    elevation="0"
    @click="goTo('__nuxt', { container: 'html', duration: 500, easing: 'easeInOutCubic' })">
    <v-icon color="surface" size="x-large">mdi-arrow-up-bold</v-icon>
  </v-btn>
</template>

<script setup lang="ts">
import { useGoTo } from 'vuetify'

const goTo = useGoTo()

const isHidden = ref(true)
let ticking = false

const handleScroll = () => {
  if (!ticking) {
    window.requestAnimationFrame(() => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop
      isHidden.value = scrollTop < 140
      ticking = false
    })
    ticking = true
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll, { passive: true })
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped lang="scss">
#totop {
  position: fixed;
  bottom: 41px;
  right: 41px;
  z-index: 1000;
  border: 6px solid rgb(var(--v-theme-white));
  transition: opacity 0.3s ease-in-out;
  &.opacity-0 {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
